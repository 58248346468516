import React from "react";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { CloseIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { createPeriodForObjectivesAction, updatePeriodForObjectivesAction } from "../../actions";
import useHandleForm from "./useHandleForm";
import Loader from "@icarius-common/loader";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

const gridStyle = { height: 75, padding: "0px 10px" };

const CreateEditDialog = (props) => {

  const {
    open,
    isLoading,
    data,
    statusList,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createPeriodForObjectivesAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updatePeriodForObjectivesAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, create, modify, openValidationError);

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {isCreate ? "Crear período para objetivos" : "Editar período para objetivos"}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <Loader open={isLoading} />
      <DialogContent>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}>
            <TextField
              disabled={!Boolean(isCreate)}
              required
              fullWidth
              margin={"none"}
              label={'Código'}
              onChange={(e) => setFormValue(e.target.value, "code")}
              value={formData.code}
              inputProps={{ maxLength: 20 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={2} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-status`}>{"Estado"}</InputLabel>
              <Select
                labelId={`label-status`}
                id={`select-status`}
                value={formData.status}
                onChange={(e) => setFormValue(e.target.value, "status")}
                margin={"none"}
              >
                {
                  statusList.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}
                    >
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
            <TextField
              required
              fullWidth
              margin={"none"}
              label={'Nombre'}
              value={formData.name}
              onChange={(e) => setFormValue(e.target.value, "name")}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={6} style={gridStyle}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                fullWidth
                required
                label={'Fecha desde'}
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                invalidDateMessage=''
                format="dd/MM/yyyy"
                margin="normal"
                value={formData.initialDate}
                onChange={(e) => setFormValue(e, "initialDate")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item alignItems="center" xs={6} style={gridStyle}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                fullWidth
                required
                label={'Fecha hasta'}
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                invalidDateMessage=""
                minDateMessage=""
                format="dd/MM/yyyy"
                margin="normal"
                value={formData.endDate}
                minDate={formData.initialDate}
                onChange={(e) => setFormValue(e, "endDate")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
        <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
      </DialogActions>
    </Dialog >
  );
}

export default CreateEditDialog;