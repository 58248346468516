import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.periodsForObjectives],
        config: {
            headerName: 'Código de período',
            field: 'code',
            filter: "agTextColumnFilter",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.periodsForObjectives],
        config: {
            headerName: 'Nombre del período',
            field: 'name',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.periodsForObjectives],
        config: {
            headerName: 'Fecha desde',
            field: "initialDate",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.periodsForObjectives],
        config: {
            headerName: 'Fecha hasta',
            field: "endDate",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.periodsForObjectives],
        config: {
            headerName: 'Estado del período',
            field: 'statusString',
            filter: "agSetColumnFilter",
        }
    },
]