import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import usePeriodsForObjectives from "./usePeriodsForObjectives";
import CreateEditDialog from "./createEditDialog/index";

const PeriodsForObjectives = ({ history }) => {

  const {
    statusList,
    isLoading,
    data,
    state,
    handlers
  } = usePeriodsForObjectives();

  const createButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog(gridRef)}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.performanceDefinition)}
      type={"goBack"}
    />

  return (
    <CommonPage
      isLoading={isLoading}
      title={'Períodos para objetivos'}
      gridTitle={'Períodos para objetivos'}
      columnDefPage={paths.periodsForObjectives}
      rowData={data}
      menuItems={[goBackItem, createButton, modifyButton, deleteButton]}
      hasExpand
      hasHelp
    >
      {
        (state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CreateEditDialog
          open={(state.createDialogIsOpen || state.editDialogIsOpen)}
          isLoading={isLoading}
          statusList={statusList}
          data={state.selectedDocument}
          handleClose={handlers.handleCloseCreateEditDialog}
        />
      }
    </CommonPage>
  )
}

export default PeriodsForObjectives;